<template>
  <div class="flix-container">
  <paymentInfos v-if="premium_smaller_x()"/>
  <serverInfos v-if="check_server_msg_date()"/>
  </div>
</template>
<script>
export default {
  components: {
    paymentInfos () { return import('./paymentInfos') },
    serverInfos () { return import('./serverInfos') }
  },
  props: {},
  data () {
    return {
      membership: false,
      days: 7
    }
  },
  computed: {

  },
  mounted () {
    this.check_premium()
  },
  methods: {
    check_server_msg_date () {
      var end = new Date()
      end.setDate(15)
      end.setMonth(3 - 1)
      end.setYear(2021)
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      end.setMilliseconds(0)

      if (end.getTime() < new Date().getTime()) {
        return false
      }
      return true
    },
    premium_smaller_x () {
      if (!this.membership) {
        return false
      }

      var days = 60 * 60 * 24 * this.days

      var checkRegister = this.check_register_after_x()

      if (this.membership.premium_counter * 1 < days && checkRegister) {
        return true
      }
      return false
    },
    check_register_after_x () {
      return true
    },
    check_premium () {
      this.$flix_post({
        url: 'user/get_membership',
        data: {
          user: this.$getUserVariables().user.md5_id
        },
        callback: function (ret) {
          this.membership = ret.data[1]
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
